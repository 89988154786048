import React, { Component } from "react";
import Highcharts from 'highcharts'
import HighchartsRegression from 'highcharts-regression'
import HighchartsReact from 'highcharts-react-official'
import FRGBigSelect from '@components/FRGBigSelect'
import Api from "@utils/api"
import ToggleSwitch from "@components/ToggleSwitch";

HighchartsRegression(Highcharts);

const EXAMPLE_DATA = {
  data: [
    [0, 1],
    [0, 1],
    [4, 2],
    [5, 2],
    [5.5, 2],
    [6, 2],
    [11.5, 3],            
    [11.8, 3],        
    [12, 3],
    [12.3, 3],                            
    [13, 3],        
    [18, 3],            
    [18, 4],        
    [22, 5],                                    
  ],
  sub_data: {
    enough_people: [50,45,40,45,35,47,60],
    too_few: [25,20,16,45,25,17,30],
    no_one: [10,15,20,35,15,27,10],
  }
}

// This chart uses immutable without animations to allow the toggle on/off graph data 
// the problem is that the regression lines don't redraw, hence the whole graph is reinitalised instead.
// This is likely due to a bug in the highcharts regression package.
class Chart extends Component {

  constructor(props) {
    super(props)

    this.state = {
      receivingLLL: true,
      question_id: props.question_id,
    }
  }

  options = (data) => {
    return {
      title: {
        text: ''
      },
      credits: {
        enabled: false,
      },
      chart: {
        type: 'scatter',
        animation: false,
        style: {
          fontFamily: "'Helvetica Neue', Helvetica, sans-serif"
        },
      },  
      plotOptions: {
        series: {
            animation: false
        }
      },      
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'horizontal',
        itemDistance: 40,
        margin: 40,
        title: {
          text: "",
        },
        itemStyle: {
          fontWeight: "500",
        }
    },  
      xAxis: {
        title: {
          enabled: true,
          text: "Months since first interview",
          style: {
            color: "#8A8A8A",
            fontWeight: "500"
          },
          margin: 50
        }, 
        gridLineWidth: 0,
        tickWidth: 0,
        labels: {
          style: {
            fontWeight: "500",
            color: "#1E1E1E"
          }
        }
      },
      yAxis: {
        title: {
          enabled: true,
          text: "Number of trusted people",
          style: {
            color: "#8A8A8A",
            fontWeight: "500"
          },
          margin: 50
        }, 
        gridLineWidth: 0,
        min: 0,
        labels: {
          style: {
            fontWeight: "500",
            color: "#1E1E1E"
          }
        }
      },  
      series: [{
        type: 'scatter',
        name: 'Completed Questionnaire',
        pointWidth: 1,
        color:  '#9360FF',
        data: data,
        marker: {
          radius: 2
        },
        regression: true,
        regressionSettings: {
          name: "Average",
          type: 'loess',
          color:  '#9360FF',
          loessSmooth: 100
        },      
      }],
    }
  }

  subOptions = (data) => {
    return {
      title: {
        text: ''
      },
      chart: {
        type: 'area',
        animation: false,
        style: {
          fontFamily: "'Helvetica Neue', Helvetica, sans-serif"
        },
      },  
      credits: {
        enabled: false,
      },      
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'horizontal',
        itemDistance: 40,
        margin: 40,
        title: {
          text: "",
        },
        itemStyle: {
          fontWeight: "500",
        }
      },  
      xAxis: {
        title: {
          enabled: true,
          text: "Months since first interview",
          style: {
            color: "#8A8A8A",
            fontWeight: "500"
          },
          margin: 50
        },
        labels: {
          style: {
            fontWeight: "500",
            color: "#1E1E1E"
          }
        },
        categories: ['0', '3-9', '9-18', '18-30', '30-42', '42-54', '54-66', '66-78', '78-90', '90-102', '102-114', '114-126'],
        gridLineWidth: 0,
        lineWidth: 0,
      },
      yAxis: {
        labels: {
          format: '{value}%',
          style: {
            fontWeight: "500",
            color: "#1E1E1E"
          }
        },
        title: {
          enabled: true,
          text: "Percentage for each answer",
          style: {
            color: "#8A8A8A",
            fontWeight: "500"
          },
          margin: 50
        }, 
        gridLineWidth: 0
      },
      marker: {

      },
      plotOptions: {
        series: {
          animation: false
        },
        area: {
            stacking: 'percent',
            marker: {
                fillColor: "#FFF",
                radius: 2
            }
        }
      },      
      series: [{
          name: "Enough People",
          color: "#43D8B5",
          fillColor: "#43D8B5",
          fillOpacity: 1,
          data: data.enough_people,
      }, {
          name: "Too Few People",
          color: "#004EFF",
          fillColor: "#004EFF",
          fillOpacity: 1,
          data: data.too_few,
      }, {
          name: "No-one",
          color: "#FF6F45",
          fillColor: "#FF6F45",
          fillOpacity: 1,
          data: data.no_one,
      }],
    }
  }  

  componentDidMount() {
    this.handleChangeQuestion(this.props.question_id)
  }

  handleChangeQuestion = (id) => {
    Api.get(`/statistics/question/${id}`, {params: {receiving_lll: this.state.receivingLLL} })
      .then(response => {
        let data = response.data 
        // data = EXAMPLE_DATA // For Testing Graphs
        this.setState({
          question_id: id,
          options: this.options(data.data),
          subOptions: this.subOptions(data.sub_data)
        })
      })
      .catch(error => {
        console.error(error)
        alert("Sorry, there was a problem loading the data")
      })
  }

  toggleReceivingLLL = () => {
    this.setState({receivingLLL: !this.state.receivingLLL}, () => this.handleChangeQuestion(this.props.question_id) )
  }

  render() {
    let options = this.props.questions.map(q => ({value: q.id, label: q.content.replace(/(<([^>]+)>)/gi, "")}))

    return (
      <section className="dashboard__question-chart">
        <div style={{marginBottom: 30}}>
          <ToggleSwitch
            leftOption="Receiving Lifelong Links"
            rightOption="Not receiving Lifelong Links"
            toggled={this.state.receivingLLL}
            onClick={this.toggleReceivingLLL}
          />

        </div>
        <div style={{marginBottom: 30}}>
          <FRGBigSelect
            options={options}
            value={options.find(o => o.value === this.state.question_id)}
            onChange={o => this.handleChangeQuestion(o.value)}
          />
        </div>

        {this.state.options && (
          <div className="dashboard__chart-container" style={{ marginBottom: 60 }}>
            <HighchartsReact
              immutable={true}
              highcharts={Highcharts}
              options={this.state.options}
            />
          </div>
        )}

        {this.state.subOptions && (
          <>
            <p style={{ marginBottom: 30 }}>Do you think this is enough people?</p>
            <div className="dashboard__chart-container">
              <HighchartsReact
                immutable={true}
                highcharts={Highcharts}
                options={this.state.subOptions}
              />
            </div>
          </>
        )}
      </section>
    )
  }
}

export default Chart;
